function getTimeRemaining(endtime) {

  let t = Date.parse(endtime) - Date.parse(new Date());

  let seconds = Math.floor((t / 1000) % 60);

  let minutes = Math.floor((t / 1000 / 60) % 60);

  let hours = Math.floor((t / (1000 * 60 * 60)) % 24);

  let days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    'total': t,
    'days': days,
    'hours': hours,
    'minutes': minutes,
    'seconds': seconds
  };
}

function initializeClock(endtime) {

  let daysSpan = document.querySelector('.timer__item:nth-child(1) .timer__num');

  let hoursSpan = document.querySelector('.timer__item:nth-child(2) .timer__num');

  let minutesSpan = document.querySelector('.timer__item:nth-child(3) .timer__num');

  let secondsSpan = document.querySelector('.timer__item:nth-child(4) .timer__num');

  let timeinterval = setInterval(updateClock, 1000);

  function updateClock() {

    let t = getTimeRemaining(endtime);

    daysSpan.innerHTML = t.days;
    hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
    minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
    secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

    if (t.total <= 0) {
      clearInterval(timeinterval);
    }
  }

  updateClock();
}

//let deadline = new Date(Date.parse(new Date()) + 15 * 24 * 60 * 60 * 1000);
let deadline = "june 15 2024 15:00:00";
initializeClock(deadline);
