import { validateForms } from '../functions/validate-forms.js';

const rules1 = [
  {
    ruleSelector: '.hero-form-name',
    rules: [
      {
        rule: 'minLength',
        value: 3,
        errorMessage: 'Минимум 3 символа'
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!'
      }
    ]
  },
  {
    ruleSelector: '.hero-form-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
];
const rules2 = [
  {
    ruleSelector: '.action-form-name',
    rules: [
      {
        rule: 'minLength',
        value: 3,
        errorMessage: 'Минимум 3 символа'
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!'
      }
    ]
  },
  {
    ruleSelector: '.action-form-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
];

const afterForm = () => {
};

validateForms('.hero__form', rules1, afterForm);
validateForms('.action__form', rules2, afterForm);
