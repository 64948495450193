import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';

const swiper = new Swiper('.gallery__swiper', {
  modules: [Navigation, Autoplay],

  // autoplay: {
  //   delay: 3000,
  // },
  slidesPerView: '1',
  speed: 1000,
  // centeredSlides: true,
  spaceBetween: 26,
  loop: true,

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  // breakpoints: {
  //   320: {
  //     slidesPerView: '1',
  //   },
  //   // when window width is >= 320px
  //   512: {
  //     slidesPerView: '1.25',
  //   },
  //   // when window width is >= 1200px
  //   993: {
  //     slidesPerView: '1.5',
  //   },
  // },
});
