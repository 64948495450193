const video = document.querySelector('.video video');
const devices = video.querySelectorAll('[data-mw]');

let confirm = false

for (let i = 0; i < devices.length; i++) {
  const source = devices[i]
  const devicesWidth = Number(source.getAttribute('data-mw'))

  if (window.innerWidth < devicesWidth && !confirm) {
    setSrcVideo(source)
    confirm = true
  } else if (devices.length == i + 1 && !confirm) {
    setSrcVideo(source)
  }
}

function setSrcVideo(source) {
  const srcVideo = source.getAttribute('data-src')
  source.setAttribute('src', srcVideo)
  source.removeAttribute('data-src')
}

video.controls = false;
video.muted = true;
video.loop = true;
video.setAttribute('playsinline', '');
video.playsinline = true;
video.preload = false;
video.load();
video.play();
