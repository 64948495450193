import './components/validateForms.js';
import './components/timer.js';
import './components/gallery.js';
import './components/swiper.js';
import './components/animation.js';
import './components/video.js';

const video = document.querySelector('.video video');

video.controls = false;
video.muted = true;
video.loop = true;
video.setAttribute('playsinline', '');
video.playsinline = true;
// video.preload = true;
video.play();
