import GraphModal from 'graph-modal';
import JustValidate from 'just-validate';
import Inputmask from "../../../node_modules/inputmask/dist/inputmask.es6.js";

const modal = new GraphModal();

const formButton = document.querySelector('.modal__button');

formButton.addEventListener('click', () => {
  modal.close('modal');
})

export const validateForms = (selector, rules, checkboxes = [], afterSend) => {
  const form = document?.querySelector(selector);
  const telSelector = form?.querySelector('input[type="tel"]');

  if (!form) {
    console.error('Нет такого селектора!');
    return false;
  }

  if (!rules) {
    console.error('Вы не передали правила валидации!');
    return false;
  }

  if (telSelector) {
    const inputMask = new Inputmask('+7 (999) 999-99-99');
    inputMask.mask(telSelector);

    for (let item of rules) {
      if (item.tel) {
        item.rules.push({
          rule: 'function',
          validator: function() {
            const phone = telSelector.inputmask.unmaskedvalue();
            return phone.length === 10;
          },
          errorMessage: item.telError
        });
      }
    }
  }

  const validation = new JustValidate(selector);

  for (let item of rules) {
    validation
      .addField(item.ruleSelector, item.rules);
  }

  if (checkboxes.length) {
    for (let item of checkboxes) {
      validation
        .addRequiredGroup(
          `${item.selector}`,
          `${item.errorMessage}`
        )
    }
  }

  validation.onSuccess((ev) => {
    const name = ev.target.querySelector('input[type="text"]').value;
    const tel = ev.target.querySelector('input[type="tel"]').value.replace(/\+/g, "");
    const referer = window.location.href;
    const utmArr = referer.split('&');
    let utm = {
      p4: '',
      p5: '',
      p6: '',
      p7: '',
      p8: '',
    };

    utmArr.forEach((element, index) => {
      let key = 'p' + (index + 4);
      element = element.slice(element.lastIndexOf('=') + 1);
      utm[key] = element;
    })

    const googleApiLink = 'https://script.google.com/macros/s/AKfycbzM2EBTsiaKJ7E_c361xIThzQIslUISor8OaFWDE-guDQr4vBaOL5LqHKwr48_R-ijv/exec';
    const url = `${googleApiLink}?&p1=${name}&p2=${tel}&p3=${referer}&p4=${utm.p4}&p5=${utm.p5}&p6=${utm.p6}&p7=${utm.p7}`;

    let xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          if (afterSend) {
            afterSend();
          }
        }
      }
    }

    xhr.open('POST', url, true);
    xhr.send();

    modal.open('modal');

    setTimeout(()=> {
      if (document.querySelector('.graph-modal-open')) {
        modal.close('modal');
      }
    }, '4000')

    ev.target.reset();
  })

};
